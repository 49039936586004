<template>
  <div>
    <v-dialog
      v-model="$store.state.dialog"
      persistent
      :overlay="false"
      max-width="1080px"
      transition="dialog-transition"
    >
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            تصميم
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid">
            <v-row v-if="!$store.state.loading">
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  label="العنوان"
                  name="title"
                  :rules="[$rules.required()]"
                  v-model="inputs.data.title"
                  placeholder="قم بإدخال العنوان"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <p class="mb-2">لون الاسم</p>
                <v-color-picker v-model="inputs.data.nameColor" />
              </v-col>
              <v-col cols="4">
                <p class="mb-2">لون الوصف</p>
                <v-color-picker v-model="inputs.data.descriptionColor" />
              </v-col>
              <v-col cols="4">
                <p class="mb-2">لون المحتوى</p>
                <v-color-picker v-model="inputs.data.contentColor" />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  label="حجم الاسم"
                  v-model="inputs.data.nameSize"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  label="حجم الوصف"
                  :rules="[$rules.required()]"
                  v-model="inputs.data.descriptionSize"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  label="حجم المحتوى"
                  :rules="[$rules.required()]"
                  v-model="inputs.data.contentSize"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="mb-2">لون الخلفية</p>
                <v-color-picker v-model="inputs.data.backgroundColor" />
              </v-col>
              <v-col cols="12" md="12">
                <div class="d-flex justify-end">
                  <v-btn :disabled="!valid" large @click="add" color="primary">
                    <v-icon left>mdi-content-save</v-icon>
                    حفظ
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <div v-else class="d-flex justify-center mt-10 pt-10">
              <v-progress-circular
                indeterminate
                size="60"
              ></v-progress-circular></div
          ></v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      inputs: {
        data: {
          title: "",
          nameColor: "",
          nameSize: "24",
          descriptionColor: "",
          descriptionSize: "18",
          contentColor: "",
          contentSize: "18",
          backgroundColor: "",
          resturant: this.$store.state.resturant.id,
        },
      },
      loading: false,
      text: "",
      valid: false,
      isEdit: false,
    };
  },
  methods: {
    async add() {
      this.$store.commit("setLoading", true);
      if (this.file != null) {
        let res = await this.$service.uploadFile(this.file);
        this.inputs.data.photo = res.id;
      }
      if (this.$store.state.dialogType == "edit") {
        await this.$http.put(
          "/designs/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        await this.$http.post("/designs", this.inputs);
      }

      this.$store.commit("setLoading", false);
      this.$root.$emit("refresh");
      this.$store.state.dialog = false;
    },
  },
  mounted() {
    // listen to the event
    this.$root.$on("fill-fields", (data) => {
      console.log(data);
      if (data.content == null) {
        data.content = "";
      }
      Object.keys(data).forEach((key) => {
        this.inputs.data[key] = data[key];
      });
      if (data.photo != null) {
        this.inputs.data.photo = data.photo.id;
        this.file = new File([data.photo], data.photo.name, {
          type: data.photo.type,
        });
      }
    });
  },
  watch: {
    "$store.state.dialog"(val) {
      if (val == false) {
        this.inputs.data = {
          title: "",
          nameColor: "",
          nameSize: "",
          descriptionColor: "",
          descriptionSize: "",
          contentColor: "",
          contentSize: "",
          backgroundColor: "",
          resturant: this.$store.state.resturant.id,
        };
      }
    },
  },
};
</script>
